html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    color: #F2F2F2;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li {
    margin-bottom: 20px;
}

p,
div {
    font-size: 16px;
}

ol {
    padding-left: 35px;
}

a {
    text-decoration: none;
    color: #F2F2F2;
}

@import url('../font/stylesheet.css');

body {
    background: #161415;
    font-family: 'Tactic Sans Extra Extended';
}


@import url('https://fonts.cdnfonts.com/css/satoshi');


/* header css 
=============================================== */
.navbar-nav .nav-item .nav-link {
    font-size: 13px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000000;
    letter-spacing: 0.5px;
    position: relative;
}

.navbar-nav .nav-item {
    display: flex;
    align-items: center;
}



.navbar-nav .nav-item.active .nav-link {
    font-weight: 900;
}

.navbar-nav .nav-item.active .nav-link:after {
    content: "";
    position: absolute;
    bottom: 0;
    background-color: #164576;
    width: 7px;
    height: 7px;
    left: calc(50% - 7px);
    border-radius: 100%;
}

@media(max-width:992px) {
    .navbar-nav .nav-item.active .nav-link:after {
        content: "";
        left: 5px;
        top: 16px;
    }

    .apr-footer {
        margin-top: 30px !important;
    }
}


.apr-header li,
.apr-footer li {
    margin-bottom: 0 !important;
}

.container.apr-navbar {
    @media(max-width: 995px) {
        background: none;
        gap: 16px;
    }

    .navbar-brand {
        @media(max-width: 800px) {
            display: none;
        }
    }

    .logo-mobile img {
        width: 100px;
    }

    .logo-mobile {
        display: none;

        @media(max-width: 995px) {
            display: block;
        }
    }

    .navbar-toggler-icon {
        @media(max-width: 995px) {
            display: none;
        }
    }

    .menu-mobile {
        display: none;

        @media(max-width: 995px) {
            display: block;
        }
    }
}

/* .donate-link {
    @media(max-width: 995px) {
        display: none !important;
    }
}

.donate-button {
    @media(max-width: 995px) {
        display: block !important;
    }
} */
.donate-button {
    display: none !important;
}

.responsive-search {
    display: none !important;
}

@media(max-width:995px) {
    .donate-button {
        display: block !important;
    }

    .donate-link {
        display: none !important;
    }

    .search-button {
        display: none !important;
    }

    .responsive-search {
        display: flex !important;
    }

    .donation-banner {
        margin-top: 0 !important;
    }

}



.header-nav {
    @media(max-width: 995px) {
        width: 100%;

        .nav-link {
            color: #fff;
        }
    }

}

.apr-header {
    @media(max-width: 995px) {
        background: #161415;
        position: absolute;
        width: 100%;
        top: 0px !important;
    }
}

@media (max-width:995px) {
    .nav-link {
        color: #ffffff !important;
        padding-left: 30px;
        font-family: 'Satoshi', sans-serif;
        font-size: 14px !important;
        margin-bottom: 20px;
    }

    .donate-btn {
        display: none;
    }

    .logo-bar .navbar-brand {
        display: none;
    }
}

@media(min-width:1250px) {
    .apr-header .container {
        max-width: 1250px;
    }
}

@media(max-width:1250px) {
    .navbar-nav .nav-item .nav-link {
        font-size: 8px;
    }

    .donate-btn {
        padding: 6px 15px !important;
    }
}


/* comman css  */

.font-50 {
    font-size: 50px !important;
}

.font-14 {
    font-size: 14px;
}

.list-style-none {
    list-style: none;
}


.color-gray-custom {
    color: #565656;
}

.flex-none {
    flex: none;
}

@media(max-width:425px) {

    .collabrate-heading,
    .the-facility-img-box h3,
    .sport-name-heading,
    .incredible-partners-heading,
    .about-page-accordian {
        font-size: 20px !important;
    }

    .sport-services-heading {
        font-size: 22px !important;
        text-align: center
    }

    .contact-page-heading,
    .banner-form h2 {
        font-size: 30px !important;
        text-align: center
    }

    .click-to-donate-heading {
        font-size: 30px !important;
    }

    .home-description {
        font-size: 16px !important;
    }

    .home-page-logo {
        width: 250px;
    }

    .explore-btn-transparent,
    .explore-btn,
    .form-white-btn {
        padding: 5px 20px !important;
    }

    .service-icon svg {
        height: 30px;
        width: 30px;
    }

    .form-donation {
        width: 300px !important;
    }

    .member-name {
        font-size: 24px !important;
    }

    .incredible-partners-logos {
        flex-direction: column !important;
        align-items: center !important;
    }

    .incredible-partners-logos img {
        width: 75%;
    }

    .click-to-donate-heading {
        text-align: center;
    }

    .click-to-donate-content {
        align-items: center;
    }

    .incredible-partners {
        padding: 50px 0px !important;
    }

    .click-to-donate,
    .about-collabrate {
        padding: 0px !important
    }

    .click-to-donate .pb-5 {
        padding-bottom: 30px !important;
    }

    .about-inner-page {
        margin-top: 40px !important;
    }

    .sport-services-text {
        font-size: 18px !important;
    }

    .sports-services-section {
        padding: 50px 16px 30px 16px !important;
    }

    .accordion-main {
        gap: 10px !important;
    }

    .container.description-main-section.py-5 {
        padding-bottom: 0px !important;
    }
}



/* apr banner css 
===================================== */

.apr-main-section {
    position: relative;
}

/* .apr-banner {
    background: linear-gradient(180deg, rgba(22, 20, 21, 0) 0%, #161415 100%), url(https://i.imgur.com/KBTA779.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    margin-top: -80px;
    padding: 0 16px;
} */

.apr-header {
    position: sticky;
    top: 45px;
    z-index: 9999;
}

.apr-navbar {
    background-color: #F2F2F2;
    border-radius: 70px;
    padding: 10px;
    box-shadow: 0 0 10px #161415;
}

.logo-bar {
    padding-left: 30px;
}

.donate-btn {
    background-color: #164576;
    border: 1px solid #F2F2F2;
    color: #FFFFFF;
    font-size: 14px;
    border-radius: 70px;
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 6px 35px;
}

.donate-btn:hover {
    background-color: #10365f;
    color: #FFFFFF;
}

.section-padding {
    padding: 100px 0;
}

.pb-2 {
    padding-bottom: 200px !important;
}

.max-width-1050 {
    max-width: 1050px;
}

.about-apr p {
    color: #ffffff;
    font-size: 20px;
    font-weight: normal;
    font-family: 'Satoshi', sans-serif;
    text-transform: uppercase;

}

.sport-img-box {
    width: 100%;
    height: 100%;
    height: 720px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
}

.sport-name-heading {
    font-size: 30px;
    font-weight: 600;
    color: #ffffff;
    text-transform: uppercase;

}

.explore-btn {
    font-size: 14px;
    color: #FFFFFF;
    border: 2px solid #ffffff;
    padding: 10px 40px;
    border-radius: 70px;
    background-color: transparent;
}

.explore-btn:hover {
    background-color: #164576;
    color: #FFFFFF;
}

.the-facility-img-box {
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    background-position: center;
}

.incredible-partners {
    padding: 150px 0px;
}

.incredible-partners-heading {
    color: #F2F2F2;
    font-size: 30px;
    font-weight: 600;
    text-transform: uppercase;
}

.incredible-partners-logos {
    display: flex;
    max-width: 1080px;
    margin: auto;
    align-items: baseline;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
    padding: 0 33px;
    grid-gap: 16px;
    row-gap: 50px;
}

.click-to-donate {
    padding-bottom: 40px;
}

/* .click-to-donate-box {
    background: #00000099 url('../img/png/donate-banner.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: 600px;
} */

.click-to-donate-heading {
    color: #F2F2F2;
    font-size: 30px;
    font-weight: 600;
    max-width: 457px;
}

.donate-white-btn {
    color: #161415;
    padding: 8px 40px;
    border-radius: 11px;
    font-size: 16px;
    font-weight: 600;
    max-width: 180px;
}

.apr-footer {
    border-top: 2px solid #164576;
    padding: 30px 0px;
}

.click-to-donate-content {
    margin-left: 300px;
}

.apr-footer-logo {
    padding-bottom: 5px;
    border-bottom: 1px solid #FFFFFF;
}

.apr-copyright {
    color: #898989;
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif;
}

@media(max-width:1500px) {
    .click-to-donate-content {
        margin-left: 0;
    }
}

@media(max-width:992px) {
    .the-facility-img-box {
        height: 720px;
    }

    .text-center-md {
        text-align: center;
    }

    .incredible-partners-logos {
        grid-template-columns: repeat(3, auto);
        justify-content: center !important;
        grid-gap: 16px !important;
        row-gap: 50px !important;
    }

}

@media(max-width:768px) {
    .the-facility-img-box {
        height: 200px;
        background-position: center;
    }

    .sport-img-box {
        height: 200px;
        background-position: center;
    }

    .about-apr p {
        font-size: 14px;
        line-height: 1.7;
        font-weight: 500;
        padding: 0 16px;
    }

    .d-done-sm {
        display: none;
    }

    .click-to-donate-heading {
        font-size: 30px;
    }

    .apr-banner {
        height: 80vh !important;
    }

    .section-padding {
        padding: 0px 0px 0px 0;
    }

    .about-apr {
        padding: 0 !important;
        display: flex;
        align-items: center;
        height: 328px;
    }

}

@media(max-width:380px) {
    .click-to-donate-heading {
        font-size: 30px;
    }
}


/* contact css 
=================================================== */
.donation-banner {
    /* background-image: url(https://i.imgur.com/0HWcP9K.png); */
    height: 100vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-top: -80px;

    @media(max-width: 768px) {
        height: 100%;
    }
}

.error-message {
    position: absolute;
    bottom: 5px;
    font-size: 16px;
    font-family: 'Satoshi', sans-serif;
    color: #ff0000;
}

.banner-form {
    text-align: center;
    padding-top: 183px;
    padding-bottom: 40px;
    /* @media(max-width: 768px){
        padding-bottom: 40px;
     } */
}

.banner-form h2 {
    font-size: 40px;
    color: #ffffff;
    font-weight: 700;
}

.form-donation {
    width: 555px;
    /* height: 474px; */
    background: #ddd;
    margin: auto;
    margin-top: 75px;
    border-radius: 40px;
    padding: 76px 55px 59px;

    @media(max-width: 768px) {
        padding: 47px 26px 40px;
        width: 400px;
    }
}

.form-donation .form-group {
    margin-bottom: 30px;

    /* @media(max-width: 768px) {
        margin-bottom: 17px;
    } */
}

.form-group input::placeholder {
    color: black !important;
}

.submit {
    margin-top: 25px;
}

.form-donation .form-group input,
.form-donation .form-group select {
    font-size: 17px;
    min-height: 56px;
    font-family: 'Satoshi';
}

.submit .btn {
    background: #164576;
    border: 1px solid #164576;
    width: 207px;
    height: 56px;
    border-radius: 9px;
    font-size: 17px;
}

.directions-container {
    max-width: 406px;
}

.directions {
    font-size: 30px;
    font-family: 'Satoshi', sans-serif;
}

.directions-description {
    font-family: 'Satoshi';
    font-size: 16px;
    margin-top: 20px;
}


/* footer css 
=================================================== */
.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    margin-top: 24px;
    flex-wrap: wrap;


    @media(max-width: 576px) {
        flex-direction: column;
        align-items: baseline;
        padding: 0 26px;
    }
}

.footer-logo {
    padding: 0 26px;
}

.footer-items {
    font-size: 14px;
    font-family: 'Satoshi', sans-serif;
    text-transform: capitalize;
}

.footer-contect {
    font-size: 10px;
    color: #D4D5D9;
    font-family: 'Satoshi', sans-serif;
}

.contact-details {
    display: none;

    @media(max-width: 576px) {
        display: block;
    }
}

.media-link {
    display: flex;

    @media(max-width: 576px) {
        display: none;
    }
}

.contact-details .form-group label {
    color: #D4D5D9;
    font-size: 10px;
    font-weight: 700;
}

.contact-details .form-group p {
    color: #ffffff;
    font-size: 14px;
    font-weight: 500;
}

.contact-details .form-group:last-child p {
    font-weight: 700;
}

.contact-details .form-group {
    margin-bottom: 10px;
}

.apr-footer-logo {
    max-width: 80px;

    @media(max-width: 576px) {
        display: none;
    }
}

.footer-logo {
    display: none;
    max-width: 132px;

    @media(max-width: 576px) {
        display: block;
    }
}

/* --------------- explore-section ----------- */

/* .explore-section:hover {
    cursor: pointer;
    transform: scale(1.01)
} */


/* football , basketball and track  css 
======================================== */


.basketball-banner {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)), url('../img/png/basketball-banner.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    margin-top: -80px;
    padding: 0 16px;
}

.track-banner {
    background: url(../img/png/track-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    margin-top: -80px;
    padding: 0 16px;
}

.apr-sport-name {
    font-size: 65px;
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.sport-contant-us {
    padding: 64px 10px;
    border-top: 5px solid #ffffff;
}


.contact-white-btn {
    font-size: 13px;
    font-weight: 400;
    color: #000000;
    padding: 14px 40px;
    background-color: #f2f2f2;
    border: 2px solid #F2F2F2;
    border-radius: 70px;
    margin-top: 40px;
    letter-spacing: 0.5px;
}



.basketball-img-1 {
    background-image: url(../img/png/basketball-img1.png);
}

.basketball-img-2 {
    background-image: url(../img/png/basketball-img2.png);
}

.basketball-img-3 {
    background-image: url(../img/png/basketball-img3.png);
}

.track-img-1 {
    background-image: url(../img/png/track-img1.png);
}

.track-img-2 {
    background-image: url(../img/png/track-img2.png);
}

.track-img-3 {
    background-image: url(../img/png/track-img3.png);
}

.sports-services-section {
    padding: 100px 16px;
    position: relative;
}

.sport-services-accordion {
    margin-top: 75px;
}

.sport-services-heading {
    font-size: 50px;
    font-weight: 600;
    color: #F2F2F2;
    text-transform: uppercase;
    letter-spacing: -5px;
}

.sport-services-text {
    font-size: 24px;
    font-weight: 900;
    font-family: 'Satoshi', sans-serif;
    color: #ffffff;
    text-transform: uppercase;

    @media (max-width:768px) {
        font-size: 20px;

    }
}

.apr-sports-img {
    width: 100%;
    height: 100%;
    height: 525px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

/* football css   responsive 
======================================= */

@media(max-width: 1190px) {
    .apr-sport-name {
        font-size: 60px;
    }

    .sport-services-heading {
        font-size: 75px;
        letter-spacing: -3px;
    }
}

@media(max-width:768px) {
    .apr-sport-name {
        font-size: 30px;
    }

    .football-banner {
        height: 70vh;
    }

    .sport-services-heading {
        font-size: 42px;
        letter-spacing: -1px;
    }

    .sport-contant-us {
        border: 0;
        padding: 0px 16px 64px 10px;
        margin-top: -80px;
        position: relative;

    }

    .football-banner {
        background-position: center !important;
    }
}

@media(max-width:370px) {
    .apr-sport-name {
        font-size: 26px;
    }
}


/* facility css
========================================= */

.facilities-banner {
    background-image: url(../img/png/facility-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    position: relative;
    margin-top: -80px;
    padding: 0 16px;
}

.facility-conatnt-heading {
    font-size: 32px;
    font-weight: 600;
    color: #ffffff;
    font-family: 'Satoshi', sans-serif;
    text-transform: uppercase;
    margin-top: 12px;
}

.facilities-list {
    padding: 0px 34px;
    position: relative;
}


/* facility responsive css
=================================== */
@media(max-width:768px) {
    .facilities-list img {
        border-radius: 14px;
        height: 360px;
        object-fit: cover;

    }

    .facility-conatnt-heading {
        font-size: 24px;
    }
}



/* team css 
==================================== */
.team-section {
    margin-top: 100px;
}

.team-section-heading {
    font-size: 55px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
    text-align: center;
}

.team-member-list {
    padding-bottom: 63px;
    display: flex;
    justify-content: center;
}

.team-member-box {
    cursor: pointer;
}

.team-member {
    padding-top: 100px;
}

.team-member-imgbox {
    max-height: 300px;
    width: 300px;
    overflow: hidden;
    border-radius: 15px;
}

.team-member-imgbox img {
    height: 100%;
    width: 100%;
    border-radius: 14px;
}

.team-member-name {
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    text-transform: uppercase;
    text-align: center;
    margin-top: 27px;
    width: 300px;
}

.team-member-position {
    font-size: 16px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Satoshi', sans-serif;
    text-align: center;
    margin-top: 10px;
    width: 300px;
}


/* team css responsive 
========================================= */
@media(max-width:786px) {
    .team-section-heading {
        font-size: 40px;
    }

    .team-section {
        margin-top: 0px;
    }

    .team-member {
        padding-top: 52px;
    }

}

@media(max-width:370px) {
    .team-section-heading {
        font-size: 30px;
        padding-top: 20px !important;
    }
}



/* about css 
====================================== */
.about-section-img {
    /* margin-top: -80px; */
    height: 100%;

}

.about-section-img img {
    @media (min-width: 992px) and (max-width: 1200px) {
        max-width: 100% !important;
        min-width: 100% !important;
    }
}

.about-page-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 635px;
    margin-left: auto;
    margin-top: 100px;
}

.about-page-heading {
    font-size: 55px;
    font-weight: 600;
    color: #ffffff;
    margin-bottom: 48px;
    word-wrap: break-word;
    text-transform: uppercase;

    @media (max-width:768px) {
        font-size: 30px !important;

    }
}

.about-page-para {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Satoshi', sans-serif;
    margin-top: 20px;
    word-wrap: break-word;
    /* max-height: 380px;
    overflow: hidden;
    overflow-y: auto; */
}

.about-page-para h3 {
    @media(max-width:768px) {
        font-size: 18px;
        line-height: 1.3;
    }
}

.about-page-accordian {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    text-transform: uppercase;
}

/* about responsive css 
===================================== */

@media(max-width:993px) {
    .d-none-lg {
        display: none;
    }

    .about-page-content {
        max-width: 100%;
        /* padding: 0 40px; */
        padding-top: 0 !important;
        margin-top: 0 !important;

    }

    .team-section {
        margin-top: 0 !important;
    }

    .team-section-heading {
        margin-top: 30px;
    }

    .about-page-heading {
        font-size: 42px;
        text-align: center;
        margin-top: 30px;
        margin-bottom: 11px;
        word-wrap: break-word;
        text-transform: uppercase;
    }

    .about-page-para {
        /* text-align: center; */
        margin-bottom: 14px;
        margin-top: 0;
        font-size: 16px;
        line-height: 1.3;

    }

    .about-page-accordian {
        font-size: 30px;
        font-weight: 400;
    }
}

/* --------------- Contact Page ----------- */
.contact-form {
    max-width: 650px;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0px 12px;
    margin-top: 100px;
}

.contact-img {
    margin-top: -80px;
}

.contact-page-heading {
    font-size: 50px;
    font-weight: 600;
    color: #F2F2F2;

}

.contact-form-inner {
    max-width: 560px;
    margin-top: 55px;
}

.contact-form-label {
    font-size: 21px;
    font-weight: 400;
    color: #F2F2F2;
    font-family: 'Satoshi', sans-serif;
    margin-bottom: 16px;
}

.contact-from-input {
    height: 44px;
    background: #F7F7F7;
    border-radius: 9px;
    border: 0;
    margin-bottom: 28px;
    padding: 10px 16px;
}

input.contact-from-input {
    font-family: 'Satoshi', sans-serif;

}

textarea.contact-from-input {
    font-family: 'Satoshi', sans-serif;
}

.search-input input {
    font-family: 'Satoshi', sans-serif;

}

.success-message {
    padding: 25px;
    text-align: center;
    color: #00db00;
    font-family: 'Satoshi', sans-serif;
    font-size: 16px;
}

.contact-main {
    margin: 0;
}

.submit-btn {
    font-size: 20px;
    padding: 11px 10px;
    width: 100%;
    font-weight: 500;
    font-family: 'Satoshi', sans-serif;
    color: #FFFFFF;
    border-radius: 9px;
    background-color: #164576;
    border: 0;
    margin-top: 5px;
}


/* contact page responsive css 
====================================== */

@media(max-width:1250px) {
    .contact-page-heading {
        font-size: 46px;
    }
}

@media(max-width:995px) {

    .contact-form {
        /* background: linear-gradient(180deg, rgba(22, 20, 21, 0) 0%, #161415 100%), linear-gradient(0deg, rgba(22, 20, 21, 0.6), rgba(22, 20, 21, 0.6)), url(../img/png/contact-bg.png); */
        background-repeat: no-repeat;
        background-repeat: no-repeat;
        background-size: cover;
        padding: 0px 12px;
    }

    .contact-page-heading {
        font-size: 32px;
        text-align: center;
        margin-top: 30px;
    }

    .contact-form {
        max-width: 100%;
        padding: 0px 32px;
        margin: 0;
    }

    .contact-form-inner {
        max-width: 100%;
        padding-bottom: 20px;
        margin-top: 25px;
    }

    .directions {
        margin-top: 0px;
    }

    .directions-container {
        max-width: 60%;
        margin-bottom: 20px !important;

    }

    .banner-form {
        padding-top: 30px;
    }

}









/* team member css 
====================================== */
.banner-img {
    margin-top: -80px;
}

.member-name {
    font-size: 50px;
    font-weight: 800;
    color: #ffffff;
    font-family: 'Tactic Sans Extra Extended';
    text-transform: uppercase;
    word-wrap: break-word;
    position: relative;
    z-index: 1;
}

.member-profile {
    font-size: 28px;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 50px;
    font-family: 'Satoshi';
    text-transform: uppercase;
    position: relative;
    z-index: 1;
}

.about-member {
    font-size: 22px;
    font-weight: 700;
    color: #ffffff;
    font-family: 'Satoshi';
    position: relative;
    z-index: 1;
}

.member-detail-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 700px;
    margin-left: auto;
    padding: 0 30px;
}

.member-detail {
    margin-top: 100px;
}

/* member detail responsive css 
===================================== */

@media(max-width:993px) {
    .d-none-lg {
        display: none;
    }

    .member-detail-container {
        max-width: 100%;
        padding: 0 33px;
        position: relative
    }

    .member-detail-container:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgb(1 173 181 / 0%) 0%, rgb(22 20 21) 95%);
    }

    .member-name {
        font-size: 38px;
        margin-top: 340px;
    }

    .member-profile {
        font-size: 28px;
        margin-bottom: 11px;
    }

    .about-page-para {
        margin-bottom: 14px;
    }

    .about-page-accordian {
        font-size: 30px;
        font-weight: 400;
    }

    .member-detail {
        margin-top: 0px;
    }
}

@media(max-width:500px) {

    .member-detail-container:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        bottom: 30%;
        left: 0;
        background-image: linear-gradient(rgb(1 173 181 / 0%) 0%, rgb(22 20 21) 95%);
    }
}

/*  -------------- Accordion --------------- */

.accordion-main {
    display: flex;
    flex-direction: column;
    gap: 46px;
    margin-top: 75px;
}

.accordion-title-button {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 10px;
}

.accordion-description {
    font-size: 23px;
    font-weight: 500;
    font-family: "Satoshi";
    color: #FFFFFF;
    margin-top: 38px;

    @media (max-width:768px) {
        font-size: 16px;
    }
}

@media(max-width: 1190px) {
    .accordion-main {
        margin-top: 19px;
    }

    .accordion-description {
        margin-top: 19px;
    }
}

@media(max-width:768px) {
    .accordion-main {
        margin-top: 19px;
    }

    .accordion-description {
        margin-top: 19px;
    }
}



/* about page open css 
====================================== */

.about-football-banner-open {
    @media (max-width:992px) {
        min-height: 400px !important;

    }

}

.about-accoridan-text {
    font-size: 25px;
    font-weight: 500;
    color: #ffffff;
    font-family: "Satoshi";
}

.about-accoridan-services {
    text-align: end;
}

.about-accoridan-services-heading {
    font-size: 25px;
    font-weight: 600;
    color: #FFFFFF;
}

.about-accoridan-services-para {
    font-size: 17px;
    font-weight: 500;
    color: #FFFFFF;
    font-family: "Satoshi";
    line-height: 27px;
    margin-top: 15px;
}

.explore-btn-transparent {
    font-size: 13px;
    font-weight: 400;
    color: #ffffff;
    padding: 14px 40px;
    border: 2px solid #F2F2F2;
    background-color: transparent;
    border-radius: 70PX;
    margin-top: 23px;
    text-transform: uppercase;
}

@media(max-width:992px) {
    .about-football-banner-open {
        background-position: center;

    }

    .about-accoridan-services-heading {
        display: none;
    }

    .about-accoridan-services-para {
        display: none;
    }

    .about-accoridan-services {
        text-align: center;
    }

    .about-accoridan-text {
        font-size: 19px;

    }

    .explore-btn-transparent {
        margin: 0;
    }

    .about-football-banner-open .mt-5 {
        margin-top: 20px !important;
    }
}

/*  ------------ sub tabs ------------ */

.dropdown-menu ul {
    list-style: none;
    padding: unset;
    margin: unset;
}

.dropdown-menu .nav-link {
    padding: 14px;
    transition: all linear 0.1s;
    color: #000000;
}

.dropdown-menu .nav-link:hover {
    background: rgb(202, 200, 200);
    cursor: pointer;
    font-weight: 700;
}

.navbar-nav .nav-item.active .dropdown-menu .nav-link {
    font-weight: 400;
}

.navbar-nav .nav-item.active .dropdown-menu .nav-link:after {
    content: "";
    width: 0px;
    height: 0px;
}

.dropdown-menu {
    box-shadow: 0px 10px 30px 0px rgba(82, 63, 105, 0.05);
    transition: all linear 0.3s;
    position: absolute;
    display: block;
    position: absolute;
    top: 35px;

    @media(max-width:992px) {
        background-color: #161415;
        margin-top: 16px;
    }

}



/* our mission css 
============================= */

.our-missions-page {
    margin-top: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 635px;
    margin-left: auto;
}

.our-missions-page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    max-width: 800px;
    margin-left: auto;
    padding: 0px 16px;
}

.upcoming-event-heading {
    font-size: 40px;
    font-weight: 600;
    font-family: 'Satoshi', sans-serif;
}

/* our mission  responsive  css
======================================== */

@media(max-width:992px) {
    .our-missions-page {
        margin-top: 0PX;
    }
}




/* collaborate css
=======================================  */

/* .collabrate-banner {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), linear-gradient(180deg, rgba(22, 20, 21, 0) 0%, #161415 100%), url(../img/png/collaborate-banner.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 100vh;
    margin-top: -80px
} */

.collabrate-page-text p {
    font-size: 20px;
}

.collabrate-heading {
    font-size: 65px;
    font-weight: 900;
    color: #ffffff;
    text-transform: uppercase;
}

/* .about-collaborate {
    background-image: url(../img/png/about-collarbate.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 1390px;
} */

.about-collabrate {
    padding-bottom: 35px;
}

.about-inner-page {
    margin-top: 100px;
    max-width: 800px;
    margin-left: auto;

}

.about-collabrate-question {
    font-size: 34px;
    font-weight: 500;
    font-family: "Satoshi";
}

.about-collabrate-answer {
    font-size: 20px;
    font-weight: 500;
    font-family: "Satoshi";
}

.about-collabrate-subpoint {
    font-size: 24px;
    font-weight: 600;
    font-family: "Satoshi";
}


@media(max-width:1350px) {
    .collabrate-heading {
        font-size: 60px;
    }

    .about-inner-page {
        max-width: 100%;
    }
}

@media(max-width:768px) {
    .collabrate-heading {
        font-size: 35px;
    }
}



/* ------------- Forms ------------ */

.form-white-btn {
    font-size: 16px;
    font-weight: 600;
    color: #000000;
    padding: 14px 40px;
    background-color: #f2f2f2;
    border: 2px solid #F2F2F2;
    border-radius: 12px;
    font-family: "Satoshi";
}

.our-missions-page-content ol {
    padding-left: 35px;
}

.our-missions-page-content ol li {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Satoshi', sans-serif;
    word-wrap: break-word;
}

.our-missions-page-content p {
    font-size: 22px;
    line-height: 30px;
    font-weight: 400;
    color: #ffffff;
    font-family: 'Satoshi', sans-serif;
    word-wrap: break-word;

    @media (max-width:768px) {
        font-size: 16px;
        line-height: 1.3;

    }
}

/* indemnity agreement  and  code of conduvt css 
=================================== */

.indemnity-form {
    margin-top: 150px !important;
    padding-bottom: 50px;
    margin: auto;
    max-width: 900px;
}

.indemnity-heading {
    font-size: 62px;
    font-weight: 700;
    font-family: "Satoshi";
    margin-bottom: 30px;
    text-transform: uppercase;
}

.about-indemnity {
    font-size: 16px;
    font-family: "Satoshi";
    color: #f2f2f2;
    line-height: 1.5;
}

.indemnity-agreement {
    margin-top: 20px;
    margin-bottom: 20px;
}

.indemnity-agreement ol {
    padding-left: 40px;
}

.indemnity-agreement ol li {
    font-size: 16px;
    font-family: "Satoshi";
    color: #f2f2f2;
    line-height: 1.5;
}

.send-btn {
    background-color: #164576;
    border: 1px solid #164576;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
    border-radius: 10px;
    box-shadow: 0px 0px 4px 0px #00000040;
    padding: 12px 35px;
    font-family: "Satoshi";
    letter-spacing: 1px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
    font-family: "Satoshi";
}


.code-of-conduct {
    margin-top: 150px !important;
    padding-bottom: 50px;
    max-width: 900px;
    margin: auto;
}


@media(max-width:992px) {
    .indemnity-heading {
        font-size: 45px;
    }
}

/*  ----------------- Home ----------------- */
.home-description {
    font-size: 20px;
    font-weight: 700;
    font-family: 'Satoshi';
    padding: 0px 16px;
}

@media(max-width:768px) {
    .football-banner:after {
        content: "";
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: linear-gradient(rgb(1 173 181 / 0%) 0%, rgb(22 20 21) 95%);
    }
}

@media(min-width:600px) {
    .member-detail-container {
        background-position: 50% 15% !important;
        background-size: cover !important;
    }
}

.banner-img img {
    max-width: 100%;
}


/*  ------------------ search list ----------------- */

.search-list,
.search-list h1,
.search-list h2,
.search-list h3,
.search-list h4,
.search-list h5,
.search-list h6,
.search-list p,
.search-list li,
.search-list span {
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}

@media(max-width:425px) {
    .responsive-search .input-group {
        width: 100% !important;
        left: -25px !important;
        top: 39px !important;

    }

    .search-values {
        top: 71px !important;
        left: -25px !important;
        width: 100% !important;
    }

    .facility-conatnt-heading {
        right: 26%;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
        font-weight: 900;
        font-size: 30px;
    }

    .facilities-list img {
        opacity: 0.5;
    }


}


.description {
    display: flex;
    flex-direction: column;
}

.error-border {
    border: 2px solid red;
}